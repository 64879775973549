.add-contact-form-dialog {
  background-color: #0d0d0d;
  color: #e0e0e0;
  border: 1px solid #26a69a;
  border-radius: 8px;
  padding: 24px;
  max-width: 800px;
  width: 100%;
  font-family: "Inter", sans-serif;
  box-shadow: 0 0 20px rgba(38, 166, 154, 0.3);
  height: 680px;
}

.add-contact-form-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
}

.add-contact-form-dialog h2 {
  text-align: center;
  margin-bottom: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #26a69a;
  text-shadow: 0 0 10px rgba(38, 166, 154, 0.5);
}

.add-contact-form-dialog-content {
  display: flex;
  gap: 24px;
}

.add-contact-form-left-side,
.add-contact-form-right-side {
  flex: 1;
}

.add-contact-form-form-group {
  background-color: #0d0d0d;
  margin-bottom: 16px;
}

.add-contact-form-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #26a69a;
  font-weight: 500;
}

.add-contact-form-form-group input[type="text"],
.add-contact-form-form-group textarea {
  width: 100%;
  padding: 10px;
  background-color: #1a1a2e;
  border: 1px solid #3d3d5c;
  color: #e0e0e0;
  font-family: "Inter", sans-serif;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.add-contact-form-form-group input[type="text"]:focus,
.add-contact-form-form-group textarea:focus {
  border-color: #26a69a;
  box-shadow: 0 0 0 2px rgba(38, 166, 154, 0.2);
  outline: none;
}

.add-contact-form-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.add-contact-form-cancel-button,
.add-contact-form-next-button,
.add-contact-form-previous-button,
.add-contact-form-save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.add-contact-form-cancel-button,
.add-contact-form-previous-button {
  background-color: #2a2a3a;
  color: #e0e0e0;
}

.add-contact-form-next-button,
.add-contact-form-save-button {
  background-color: #26a69a;
  color: #0f0f1a;
}

.add-contact-form-cancel-button:hover,
.add-contact-form-next-button:hover,
.add-contact-form-previous-button:hover,
.add-contact-form-save-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.add-contact-form-layout-section,
.add-contact-form-image-section,
.add-contact-form-content-section,
.add-contact-form-data-capture-fields {
  margin-bottom: 24px;
}

.add-contact-form-layout-options {
  display: flex;
  gap: 16px;
}

.add-contact-form-layout-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background-color: #1a1a2e;
  border: 1px solid #3d3d5c;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-contact-form-layout-button.active {
  border-color: #26a69a;
  background-color: rgba(38, 166, 154, 0.1);
}

.add-contact-form-layout-button svg {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
  color: #26a69a;
}

.add-contact-form-image-upload-label {
  display: block;
  width: 120px;
  height: 120px;
  margin-top: 8px;
  border: 2px dashed #3d3d5c;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.add-contact-form-image-upload-label:hover {
  border-color: #26a69a;
  box-shadow: 0 0 15px rgba(38, 166, 154, 0.3);
}

.add-contact-form-upload-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #3d3d5c;
}

.add-contact-form-upload-placeholder svg {
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}

.add-contact-form-uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add-contact-form-module-preview,
.add-contact-form-mobile-preview {
  background-color: #1a1a2e;
  border: 1px solid #3d3d5c;
  border-radius: 8px;
  padding: 16px;
}

.add-contact-form-preview-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-contact-form-preview-content.left {
  flex-direction: row;
  align-items: flex-start;
}

.add-contact-form-preview-image {
  width: 100px;
  height: 100px;
}

.add-contact-form-preview-image.left {
  margin-right: 16px;
}

.add-contact-form-preview-image.center {
  margin-bottom: 16px;
}

.add-contact-form-preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.add-contact-form-preview-text h4 {
  margin: 0 0 8px;
  color: #26a69a;
}

.add-contact-form-preview-text.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-contact-form-field-row {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid #26a69a;
  border-radius: 4px;
}

.add-contact-form-field-name {
  flex: 1;
}

.add-contact-form-required-label {
  margin-left: 8px;
  font-size: 12px;
  color: #6c6c8b;
}

.add-contact-form-mobile-frame {
  background-color: #0d0d0d;
  border: 2px solid #3d3d5c;
  border-radius: 16px;
  padding: 16px;
  max-width: 300px;
  margin: 0 auto;
  height: 460px;
  overflow-y: scroll;
}

.add-contact-form-mobile-frame h4 {
  margin: 0 0 8px;
  color: #26a69a;
}

.add-contact-form-mobile-frame .add-contact-form-form-group {
  margin-bottom: 12px;
}

.add-contact-form-mobile-frame label {
  font-size: 14px;
}

.add-contact-form-mobile-frame input,
.add-contact-form-mobile-frame textarea {
  width: 100%;
  padding: 8px;
  background-color: #1a1a2e;
  border: 1px solid #3d3d5c;
  color: #e0e0e0;
  font-family: "Inter", sans-serif;
  border-radius: 4px;
  font-size: 14px;
}

.add-contact-form-required {
  color: #ff4d4d;
  margin-left: 4px;
}

.add-contact-form-error {
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 4px;
}

.add-contact-form-title {
  font-size: 22px;
}

/* .add-contact-form-hidden-input {
  display: none;
} */

.add-contact-form-section-title {
  font-size: 20px;
}

.add-contact-form-layout-section,
.add-contact-form-image-section,
.add-contact-form-content-section {
  border: 1px solid #3d3d5c;
  border-radius: 4px;
  padding: 15px;
}

.add-contact-form-field-row.new-field {
  background-color: #1a1a2e;
}

.add-contact-form-field-input input {
  color: white;
  border: 1px solid white;
  border-radius: 4px;
}

.add-contact-form-field-icon {
  color: white;
}

.add-contact-form-fields-list {
  height: 350px;
  overflow-y: scroll;
}

.add-contact-form-title {
  font-size: 22px;
  margin: 0 !important;
}

.add-contact-form-header {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
  position: relative;
}

.add-contact-form-close-button {
  position: absolute;
  right: 0;
}

.add-contact-form-x {
  width: 24px;
  height: 24px;
}
/* Dark Theme with Realistic Mobile Mockup */

/* Variables */
:root {
  --card-background: #1a1a1a; /* Slightly lighter for cards */
  --text-color: #e0e0e0; /* Light gray text */
  --accent-blue: #00bcd4; /* Muted cyan for Link */
  --accent-orange: #ff7043; /* Muted orange for Event */
  --accent-purple: #ab47bc; /* Muted purple for Text */
  --accent-green: #26a69a; /* Muted green for Contact */
  --border-color: #333333; /* Subtle border color */
  --button-hover-bg: #212121; /* Button hover background */
}

/* Main Layout */
.template-builder {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .template-builder {
    grid-template-columns: 1fr 1fr;
  }
}

.builder-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  background: black;
  border-right: 1px solid var(--border-color);
  overflow: auto;
}

.builder-header {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; /* Smaller heading */
  font-weight: bold;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  letter-spacing: 0.5px; /* Subtle spacing for style */
  position: relative;
}

.title1 {
  font-size: 26px;
  margin-bottom: 10px;
}

.builder-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Button Grid */
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

/* Muted Neon Buttons */
.add-block-button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem; /* Adjust button text size */
  font-weight: bold;
  color: var(--text-color);
  background: var(--card-background);
  border: 1px solid var(--border-color);
  transition: background 0.2s ease, transform 0.2s ease;
  cursor: pointer;
}

/* Individual Button Colors */
.add-block-button.link {
  color: var(--accent-blue);
}

.add-block-button.event {
  color: var(--accent-orange);
}

.add-block-button.contact {
  color: var(--accent-green);
}

.add-block-button.text {
  color: var(--accent-purple);
}

/* Hover Animation for Buttons */
.add-block-button:hover {
  background: var(--button-hover-bg);
  transform: translateY(-2px);
}

/* Scrollable Area */
.blocks-list {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

/* Cards */
.block-card {
  background: var(--card-background);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
}

/* Preview Pane */
.preview-pane {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: black;
}

.preview-header {
  font-size: 1rem; /* Smaller preview heading */
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 1rem;
}

/* Realistic Screen Details */
.phone-header {
  height: 40px;
  background: var(--card-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 1px solid var(--border-color);
}

.title2 {
  font-size: 20px;
  text-align: center;
}

.phone-header-icon {
  width: 20px;
  height: 20px;
  background: var(--text-color);
  border-radius: 50%;
}

/* Preview Block */
.preview-block {
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 1rem;
  width: 100%;
}

.preview-block-desktop {
  margin-bottom: 0;
  border: 1px solid var(--text-color);
}

.preview-block-desktop .ant-card-body {
  padding: 24px 20px;
}

.preview-link {
  color: var(--accent-blue);
  font-weight: bold;
  text-decoration: none;
}

.preview-link:hover {
  text-decoration: underline;
}

.preview-text,
.preview-contact,
.preview-event {
  color: var(--text-color);
}


/* iPhone Mockup with Notch */
.phone-mockup {
  position: relative;
  width: 320px;
  height: 640px;
  margin: 0 auto;
  background: var(--card-background);
  border-radius: 36px; /* Rounded edges for iPhone look */
  border: 4px solid var(--border-color);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  overflow: hidden;
}

.phone-notch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 20px;
  background: #0d0d0d;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.phone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  color: var(--text-color);
  padding: 40px 20px 20px;
}

/* Profile Section */
.profile-section {
  text-align: center;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Avatar */
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--border-color);
  margin: 0 auto 0.5rem;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Username */
.username {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 1rem;
}

/* Social Media Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.social-icon {
  width: 24px;
  height: 24px;
  color: var(--text-color);
  transition: color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.social-icon:hover {
  color: var(--accent-blue); /* Change icon color on hover */
  transform: scale(1.1); /* Slightly enlarge icon */
}

/* General Styling */
.blocks-list {
  padding: 1.5rem;
}

.blocks-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Block Section */
.block {
  background: #1e1e1e;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.block-header {
  font-size: 1.25rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  border-bottom: 1px solid #333333;
  padding-bottom: 0.5rem;
}

/* Grid for Cards */
.block-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

/* Individual Cards */
.block-card {
  display: flex;
  align-items: center;
  background: #252525;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.block-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
}

/* Avatar */
.block-avatar {
  flex-shrink: 0;
  margin-right: 1rem;
  background: #333333;
}

/* Card Content */
.block-content {
  flex: 1;
}

.block-title {
  color: #00bcd4;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.block-title:hover {
  color: #26a69a;
}

.block-description {
  color: #e0e0e0;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
}

.block-description.sold-out {
  text-decoration: underline;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .block-grid {
    grid-template-columns: 1fr;
  }
}

.preview-block .ant-card-body {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.preview-block.event {
  cursor: pointer;
}

.image-wrapper-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
}

.text-center {
  margin: 0px;
}

.preview-contact {
  width: 100%;
}

.content-wrapper-left {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.block-card-contact-form .ant-card-body {
  display: flex;
}

.block-content-contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event-card-wrapper {
  width: 86px;
  height: 86px;
  background: #333333;
  border-radius: 4px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event-card-info-secondary,
.event-card-info-primary {
  color: white;
  margin: 0;
}

.event-card-info-primary {
  font-size: 24px;
  font-weight: 700;
}

.event-card-info-secondary {
  opacity: 0.6;
}

.social-icons-wrapper {
  display: flex;
  gap: 14px;
  font-size: 19px;
}

.facebook-icon {
  color: #1877F2;
  opacity: 0.8;
}

.instagram-icon {
  color: #E1306C;
  opacity: 0.8;
}

.soundcloud-icon {
  color: #FF5500;
  opacity: 0.8;
}

.youtube-icon {
  color: #FF0000;
  opacity: 0.8;
}

.twitter-icon {
  color: #1DA1F2;
  opacity: 0.8;
}

.spotify-icon {
  color: #1DB954;
  opacity: 0.8;
}

.analytics-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--card-background);
  border: 1px solid #3d3d5c;
  color: #00ffff;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  right: 10px;
  margin-bottom: 20px;
}

.analytics-button:hover {
  background-color: #2a2a3a;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 255, 255, 0.2);
}

.page-wrapper {
  padding-top: 16px;
}

/* Draggable Blocks Styling */
.draggable-block {
  background: var(--card-background);
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: grab;
  user-select: none;
}

/* Block Hover Effect */
.draggable-block:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

/* Dragging State */
.draggable-block:active {
  transform: scale(1.04);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
}

/* Drag Handle */
.drag-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  margin-right: 12px;
  border-radius: 6px;
  transition: background 0.2s ease-in-out;
  align-self: flex-start;
  background: transparent;
  padding: 0;
}

.drag-handle .lucide-grip-vertical {
  width: 22px;
  height: 22px;
}

/* Drag Handle Hover */
.drag-handle:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Drag Handle Icon */
.drag-handle svg {
  color: var(--text-color);
  width: 18px;
  height: 18px;
  opacity: 0.7;
}

/* Placeholder when dragging */
.droppable-placeholder {
  background: rgba(255, 255, 255, 0.1);
  border: 2px dashed var(--border-color);
  height: 80px;
  border-radius: 10px;
  margin-bottom: 12px;
}
